import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router  from './router'
import store from './store'
import './plugins/element.js'
Vue.config.productionTip = false
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import Axios from 'axios'

Vue.config.productionTip = false

// Vue.prototype.$axios = Axios

Axios.defaults.baseURL = '/api'
// 设置路由名称（title名册）
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})





Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
new Vue({
  router,
  Axios,
  store,
  render: h => h(App)
}).$mount('#app')
