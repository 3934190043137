<!--
 * @Author: clc frank.clc@qq.com
 * @Date: 2023-06-06 13:00:42
 * @LastEditors: clc frank.clc@qq.com
 * @LastEditTime: 2023-12-07 19:14:10
 * @FilePath: \lanm\src\components\Banner.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="content">
    <el-carousel trigger="click" :height="swHeight + 'px'">
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <img ref="imgHeight" @load="getIngHeight(index)" :src="imgList[index]" alt="" @click="toDo(index)" style="cursor: pointer;" :height="swHeight + 'px'" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data () {
    return {
      pageName: 'index',
      swHeight: '',
      imgList: [
        require('@/assets/images/banner.jpg'),
        require('@/assets/images/lanxiaoai1.jpg'),
        // require('@/assets/images/banner.jpg'),
        require('@/assets/images/lanxiaoai2.jpg'),
      ],
    };
  },
  mounted () {
    //   检测窗口变化
    window.onresize = () => {
      return (() => {
        this.getIngHeight(0);
      })();
    };
  },
  beforeDestroy () {
    window.onresize = null;
  },
  methods: {
    getIngHeight (i) {
      if (i === 0) {
        this.swHeight = this.$refs.imgHeight[0].offsetHeight;
      }
    },
    toDo (index) {
      if (index === 0) {
        this.$router.push({ path: '/downloadzone', query: { index: 1 } }).catch(_err => { console.log(_err) })
      }
      if (index === 1 || index === 2) {
        // window.open(
        //   'http://p.baominggongju.com/share.html?eid=6144035c827e376a5c192543'
        // );
        this.$router.push({ path: '/huodong', query: { index: 1 } }).catch(_err => { console.log(_err) })
      }
    },
  },
};
</script>

<style scoped lang="less">
.content {
  width: 100%;
  min-width: 1000px;
  margin: 0;
  padding: 0;

  img {
    width: 100%;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
