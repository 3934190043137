<template>
  <div>
    <div class="top-tap">
      <div
        class="tap-con"
        @click="changePage(0)"
        :class="num === 0 ? 'tap-active' : ''"
      >
        <div class="tap-img">
          <img
            v-if="num != 0"
            :src="require('@/assets/images/xun-icon1.png')"
            alt=""
          />
          <img
            v-if="num === 0"
            :src="require('@/assets/images/xun-icon1c.png')"
            alt=""
          />
        </div>
        <div class="tap-font">篮子策略</div>
      </div>
      <div
        class="tap-con"
        @click="changePage(1)"
        :class="num === 1 ? 'tap-active' : ''"
      >
        <div class="tap-img">
          <img
            v-if="num != 1"
            :src="require('@/assets/images/xun-icon2.png')"
            alt=""
          />
          <img
            v-if="num === 1"
            :src="require('@/assets/images/xun-icon2c.png')"
            alt=""
          />
        </div>
        <div class="tap-font">单维策略</div>
      </div>
      <div
        class="tap-con"
        @click="changePage(2)"
        :class="num === 2 ? 'tap-active' : ''"
      >
        <div class="tap-img">
          <img
            v-if="num != 2"
            :src="require('@/assets/images/xun-icon4.png')"
            alt=""
          />
          <img
            v-if="num === 2"
            :src="require('@/assets/images/xun-icon4c.png')"
            alt=""
          />
        </div>
        <div class="tap-font">多品种筛选及分组管理</div>
      </div>
      <div
        class="tap-con"
        @click="changePage(3)"
        :class="num === 3 ? 'tap-active' : ''"
      >
        <div class="tap-img">
          <img
            v-if="num != 3"
            :src="require('@/assets/images/xun-icon5.png')"
            alt=""
          />
          <img
            v-if="num === 3"
            :src="require('@/assets/images/xun-icon5c.png')"
            alt=""
          />
        </div>
        <div class="tap-font">各交易端皆可接入</div>
      </div>
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
    };
  },
  mounted() {
    this.num = 0;
  },
  watch: {
    
    $route: {
      immediate: true,
      handler(val) {
        switch (val.fullPath) {
          case '/basket':
            this.num = 0
            break;
          case '/onewey':
            this.num = 1
            break;
          case '/sizer':
            this.num = 2
            break;
          case '#':
            this.num = 3
            break;
        }
      },
      deep: true,
    },
    num: {
      immediate: true,
      handler(newValue) {
        switch (newValue) {
          case 0:
            this.$router.push("/basket");
            break;
          case 1:
            this.$router.push("onewey");
            break;
          case 2:
            this.$router.push("/sizer");
            break;
          case 3:
            this.$router.push("#");
            break;
        }
      },
    },
  },
  methods: {
    changePage(n) {
      this.num = n;
    },
  },
};
</script>

<style scoped lang="less">
.top-tap {
  width: 100%;
  max-width: 1200px;
  min-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  border-bottom: 3px solid #f4f6f5;
  padding: 30px 100px 0;
  box-sizing: border-box;
  .tap-con {
    cursor:pointer;
    width: 25%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tap-img {
      padding: 15px 0;
    }
    .tap-font {
      padding: 10px 0;
      font-size: 14px;
      color: #999999;
      position: relative;
    }
  }
  .tap-active {
    .tap-font {
      color: #3573ec;
    }
  }
  .tap-active::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 103px;
    display: block;
    height: 4px;
    border-radius: 2px;
    background-color: #3573ec;
  }
}
</style>