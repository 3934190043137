import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/basket' },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    meta: {
      title: '蓝目数据',
    },
    children: [
      {
        path: '/basket',
        name: 'Basket',
        component: () => import('../components/home/Basket.vue'),
        meta: {
          title: '蓝目数据-篮子策略',
        },
      },
      {
        path: '/onewey',
        name: 'OneWey',
        component: () => import('../components/home/OneWey.vue'),
        meta: {
          title: '蓝目数据-单维策略',
        },
      },
      {
        path: '/sizer',
        name: 'Sizer',
        component: () => import('../components/home/Sizer.vue'),
        meta: {
          title: '蓝目数据-多品种筛选及分组管理',
        },
      },
    ],
  },
  {
    path: '/interface',
    name: 'Interface',
    component: () => import('../components/home/Interface.vue'),
    meta: {
      title: '蓝目数据-关于蓝目',
    },
  },
  {
    path: '/joinus',
    name: 'Interface',
    component: () => import('../views/JoinUs.vue'),
    meta: {
      title: '蓝目数据-招聘英才',
    },
  },
  { path: '/videoteac', redirect: '/kpcon' },
  {
    path: '/videoteac',
    name: 'VideoTeac',
    component: () => import('../views/VideoTeac.vue'),
    children: [
      {
        path: '/clcon',
        name: 'clcon',
        component: () => import('../components/polyv.vue'),
        meta: {
          title: '蓝目数据-策略训练',
        },
      },
      {
        path: '/kpcon',
        name: 'kpcon',
        component: () => import('../components/kpTeach.vue'),
        meta: {
          title: '蓝目数据-科普教学',
        },
      },
    ],
    meta: {
      title: '蓝目数据-视频教学',
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: {
      title: '蓝目数据-视频教学',
    },
  },
  {
    path: '/kaifa',
    name: 'Kaifa',
    component: () => import('../views/Kaifa.vue'),
    meta: {
      title: '蓝目数据-开发者乐园',
    },
  },
  {
    path: '/downloadzone',
    name: 'DownloadZone',
    component: () => import('../views/DownloadZone.vue'),
    meta: {
      title: '蓝目数据-下载专区',
    },
  },
  {
    path: '/helpcenter',
    name: 'HelpCenter',
    component: () => import('../views/HelpCenter.vue'),
    meta: {
      title: '蓝目数据-下载专区',
    },
  },
  {
    path: '/getperm',
    name: 'GetPerm',
    component: () => import('../views/GetPerm.vue'),
    meta: {
      title: '蓝目数据-开通会员',
    },
  },
  {
    path: '/huodong',
    name: 'HuoDong',
    component: () => import('../views/huodong202312.vue'),
    meta: {
      title: '蓝目数据-开通会员',
    },
  },
]

const router = new VueRouter({
  routes,
})

export default router
