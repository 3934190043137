import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({

  // state相当于组件中的data，专门用来存放全局的数据
  state: {
    // 需要存储的变量
    user: {} ,  // 存储用户信息
    token:''
  },
  getters:{

  },
  mutations: {

    setUser(state, user) {
      if (user)
        state.user = user
      else
        state.user = {}
    },
    removeUser(state) {
      state.user = {}
      state.token = ''
    },
    setToken(state, token){
      if (token)
        state.token = token
      else
        state.token = ''
    }
  },
  actions: {

  },
  modules: {

  }
})
