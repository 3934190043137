<template>
  <div class="content">
    <div class="topcon">
      <div class="left-logo">
        <div>
          <img :src="require('@/assets/images/lanm1.png')" alt="" />
        </div>
        <div class="logoname">蓝目数据</div>
      </div>
      <div class="right-nav">
        <div
          :class="headActive === '/basket' ? 'active' : ''"
          @click="toIndex('/basket')"
        >
          首页
        </div>
        <div
          :class="headActive === '/kaifa' ? 'active' : ''"
          @click="toIndex('/kaifa')"
        >
          开发者乐园
        </div>
        <div
          :class="headActive === '/downloadzone' ? 'active' : ''"
          @click="toIndex('/downloadzone')"
        >
          下载专区
        </div>
        <div
          :class="headActive === '/joinus' ? 'active' : ''"
          @click="toIndex('/joinus')"
        >
          招聘英才
        </div>
        <div
          :class="headActive === '/interface' ? 'active' : ''"
          @click="toIndex('/interface')"
        >
          关于蓝目
        </div>
        <div
          :class="headActive === '/videoteac' ? 'active' : ''"
          @click="toIndex('/videoteac')"
        >
          视频教学
        </div>
        <div
          :class="headActive === '/helpcenter' ? 'active' : ''"
          @click="toIndex('/helpcenter')"
        >
          帮助中心
        </div>
<!--        <div
          :class="headActive === '/getperm' ? 'active' : ''"
          @click="toIndex('/getperm')"
        >
          成为会员
        </div> -->
        <!-- <div
          v-if="!isLogin"
          :class="headActive === '/register' ? 'active' : ''"
          @click="toIndex('/register')"
        >
          登录/注册
        </div>
        <div
          v-else
          :class="headActive === '/register' ? 'active' : ''"
          @click="toIndex('/register')"
        >
          退出登录
        </div> -->
        <div @click="toNew">用户中心</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLogin: false,
    }
  },
  computed: {
    headActive() {
      let myPath = '/basket'
      let path = this.$router.history.current.fullPath
      switch (path) {
        case '/basket' || '/onewey' || '/sizer':
          myPath = '/basket'
          break
        case '/kaifa':
          myPath = '/kaifa'
          break
        case '/downloadzone':
          myPath = '/downloadzone'
          break
        case '/joinus':
          myPath = '/joinus'
          break
        case '/interface':
          myPath = '/interface'
          break
        case '/kpcon' || '/clcon':
          myPath = '/videoteac'
          break
        case '/helpcenter':
          myPath = '/helpcenter'
          break
        case '/getperm':
          myPath = '/getperm'
          break
      }
      return myPath
    },
  },
  watch: {
    isLogin(val) {
      this.isLogin = this.getCookie('account')
      console.log(val)
    },
  },
  created() {
    this.isLogin = this.getCookie('account')
  },

  methods: {
    toIndex(val) {
      this.$router.push(val)
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      var expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + '; ' + expires
    },
    getCookie: function (key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + '=')
        if (start !== -1) {
          start = start + key.length + 1
          var end = document.cookie.indexOf(';', start)
          if (end === -1) end = document.cookie.length
          return unescape(document.cookie.substring(start, end))
        }
      }
      return false
    },
    toNew() {
      window.open(
        'http://h5.lanmuda.com.cn/tradeinfo/dist/index.html',
        '_blank'
      )
    },
  },
}
</script>
<style scoped lang="less">
.active {
  position: relative;
}
.active::after {
  content: '';
  width: 30px;
  position: absolute;
  height: 3px;
  margin-left: 50%;
  transform: translateX(-50%);
  background: #0286b7;
  border-radius: 1.5px;
  left: 0;
  bottom: -8px;
}
.content {
  width: 100%;

  height: 130px;
  .topcon {
    width: 100%;
    height: 130px;
    max-width: 1230px;

    min-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 0 auto;
    .left-logo {
      display: flex;
      align-items: center;
      .logoname {
        padding-left: 10px;
        font-size: 24px;
        font-weight: 600;
        color: #0286b7;
      }
    }
    .right-nav {
      color: #666666;
      display: flex;
      align-items: center;

      :nth-child(n) {
        font-size: 18px;
        padding: 0 10px;
        cursor: pointer;
        a {
          color: #666666;
          text-decoration: none;
        }
      }
      :nth-child(n):hover {
        color: #0094b5;
      }
    }
  }
}
</style>
