<template>
  <div class="content">
    <header-nav></header-nav>
    <banner></banner>
    <index-taps></index-taps>
    <floor></floor>
  </div>
</template>

<script>
import HeaderNav from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import Floor from '@/components/Floor.vue'
import IndexTaps from '@/components/IndexTaps.vue'
export default {
   components:{
    HeaderNav,
    Banner,
    Floor,
    IndexTaps
  },
};
</script>

<style lang="less" scoped>

</style>